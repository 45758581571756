export const dummyDataFor_copysql =[
    {
        id:"1",
        projectName:"Tranning Data Set Q1 Test",
        configSet:"Oracle Accounts",
        table:"Accounts",
        firstNameField:"Accounts_first",
        lastNameField:"Accounts_last",
        group:["DROP_TABLE"]

    }
]
export const inputField_copy_name = [
    {
        label: 'First name column',
        name: 'firstName',
        type: 'text',
        placeholder: 'Enter first name column',
        required: true,
    },
    {
        label: 'Last name column',
        name: 'lastName',
        type: 'text',
        placeholder: 'Enter last name column',
        required: true,
    },
    
    
];
export const inputField_FORM_TABLE=[
    {
        label: 'Table for first name column',
        name: 'firstNameTable',
        type: 'text',
        placeholder: '',
        required: true,
    },
    {
        label: 'Schema for first name column',
        name: 'firstSchema',
        type: 'text',
        placeholder: '',
    },
   
]
export const inputField_QUERY_Table =[
    {
        label: 'Select SQL',
        name: 'selectSQL',
        type: 'text',
        placeholder: 'Enter select SQL',
        required: true,
    }
]
export const inputField_FORM_TABLE_DIFFRENT_SOURCE =[
    {
        label: 'Table for last name column',
        name: 'lastNameTable',
        type: 'text',
        placeholder: '',
        required: true,
    },
    {
        label: 'Schema for last name column',
        name: 'lastSchema',
        type: 'text',
        placeholder: 'Enter last name column',
    },
]