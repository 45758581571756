import React, { useContext, useEffect, useState } from "react";
import Copy_name from "./Copy_name";
import TablePro from "../../../components/newTable/TablePro";
import {
  dummyDataFor_copysql,
  inputField_copy_name,
} from "./nameSource_dummyData";
import { BiEdit, BiTrash } from "react-icons/bi";
import { UserContext, useUserContext } from "../../../hooks/UserContext";
import { MdCreate } from "react-icons/md";
import { serverAxios } from "../../../utils/AxiosUtils";
import { BsEye } from "react-icons/bs";
import Detail_data_copy_name from "./Detail_data_copy_name";

const Name_source = () => {
  const { setLoading } = useContext(UserContext);
  const { showToast } = useUserContext();
  const [alldata, setAllData] = useState(null)
  const [copyName, setCopyName] = useState({ show: false, id: {} });
  const [tableData, setTableData] = useState(null);
  const column = [
    {
      name: "Config Set",
      field: "configSetName",
    },
    {
      name: "First Name Column",
      field: "firstNameColumnName",
    },
    {
      name: "Last Name Field",
      field: "lastNameColumnName",
    },
    // {
    //   name: "Table",
    //   field: "tableName",
    // },
    // {
    //   name:"selectQuery",
    //   field:"selectQuery"
    // },
    // {
    //   name:"schemaName",
    //   field:"schemaName"
    // },
    // {
    //   name:"lastNameTable",
    //   field:"lastNameTable"
    // },
    // {
    //   name:"lastNameSchema",
    //   field:"lastNameSchema"
    // },
    {
      name:"Status",
      field:"status"
    },
    {
      name:"Record Count",
      field:"recordCount"
    },
    {
      name: "Action",
      type: "customControl",
      color: "rgba(16, 24, 40, 1)",
      fnRenderCustomControl: (i) => {
        return (
          <div className=" d-flex gap-2">
            <div
              className="level-text cursor-pointer"
              onClick={() =>setAllData(i) }
            >
              <BsEye size={20} />
            </div>
           
          </div>
        );
      },
    },
    
  ];
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    serverAxios.appSafeAxios.get("utils/name-replacement").then((res) => {
      setTableData(res.data.data);
    });
  };
  return (
    <>
      {copyName.show && (
        <Copy_name
          setCopyName={setCopyName}
          copyName={copyName}
          inputField={inputField_copy_name}
          refresh={getData}
        />
      )}
      {alldata&&<><Detail_data_copy_name allData={alldata} setAlldata={setAllData}/></>}
      <div className="d-flex justify-content-between pt-3">
        <h2 className="font-5">Name Source</h2>
        <div className="">
          <button
            className="btn-purple "
            onClick={() => setCopyName({ show: true, id: "" })}
          >
            <MdCreate /> Create
          </button>
        </div>
      </div>
      {tableData&&
      <TablePro data={tableData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )} columns={column} />}
    </>
  );
};

export default Name_source;
