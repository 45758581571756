import React, {createContext, useContext, useEffect, useState} from 'react';
import axios from "axios";
import {AppSafeUrl} from "../model/AppSafeUrl";
import {Outlet, useLocation} from "react-router-dom";
import {serverAxios} from "../utils/AxiosUtils";
import { UserContext, useUserContext } from "./UserContext";

const NewRunContext = createContext(null);

export function useNewRunContext() {
    return useContext(NewRunContext);
}

export function NewRunDataProvider({children}) {
    
    const { showToast } = useUserContext();

    const {state} = useLocation();
    const [data, setData] = useState(state || {
        runName: '',
        executionSet: null,
        scheduleTime: null,
        reRunType:true,
        executionSetName:null,
    });
    const [executionSets, setExecutionSets] = useState([])

    useEffect(() => {
        serverAxios.appSafeAxios.get("execution-set-details")
            .then(res => {
                const receivedData = res.data;
                if (!receivedData.success) {
                    throw new Error(receivedData);
                }
                setExecutionSets(receivedData.data);
            }).catch(err => {
                showToast(err.message, "fail")
            console.error("The execution set error ->", err);
        }).finally(() => {

        })
    }, []);
    const updateData = (newData) => {
        setData(newData);
    };


    return (
        <NewRunContext.Provider value={{data, executionSets, updateData}}>
            <Outlet/>
        </NewRunContext.Provider>
    );
}
