import React from "react";
import { RxCross2 } from "react-icons/rx";

const Detail_data_copy_name = ({ setAlldata, allData }) => {
  const display = (title, data) => {
    return (
      <div className="flex">
        <div className="font-4 level-text">{title} : </div>
        <div className="font-4">{data}</div>
      </div>
    );
  };
  return (
    <div
      className="panel-utility pl-2 h-100"
      style={{ padding: "16px 15px 12px 12px" }}
    >
      <div className="h-100 panel-screen gap-4 d-flex flex-column">
        <div className="d-flex justify-content-between w-100">
          <h4 className="font-5">All data</h4>
          <RxCross2
            className="level-text cursor-pointer"
            onClick={() => setAlldata(null)}
            size={25}
          />
        </div>
        {display("ConfigSet name", allData?.configSetName)}
        {display("Created At", allData?.createdAt)}
        {display("Created By", allData?.createdBy)}
        {display("FirstName ColumnName", allData?.firstNameColumnName)}
        {display("LastName ColumnName", allData?.lastNameColumnName)}
        {display("LastName Schema", allData?.lastNameSchema)}
        {display("LastName Table", allData?.lastNameTable)}
        {display("Message", allData?.message)}
        {display("Record Count", allData?.recordCount)}
        {display("Schema Name", allData?.schemaName)}
        {display("Select Query", allData?.selectQuery)}
        {display("Status", allData?.status)}
        {display("Table Name", allData?.tableName)}
        {display("Updated At", allData?.updatedAt)}
        {display("Updated By", allData?.updatedBy)}

      </div>
    </div>
  );
};

export default Detail_data_copy_name;
