import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Running.scss";
import { BiArrowBack, BiFilter, BiRectangle, BiRefresh } from "react-icons/bi";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { UserContext, useUserContext } from "../../hooks/UserContext";
import dUtilitySetSingleEntry from "../../model/UtilitySetSingleEntry.json";
import MultiColourProgressBar from "../../components/MultiColourProgressBar/MultiColourProgressBar";
import CircleProgressBar from "../../components/CircleProgressBar/CircleProgressBar";
import midDataTable from "../../model/UtilitySetMidleTable.json";

import { getAllJObs } from "../../hooks/apis/useAppSafeApi";

const Runing = () => {
  const { showToast } = useUserContext();
  const { user, isLoading, setLoading } = useContext(UserContext);
  const [showNewDiv, setShowNewDiv] = useState(false);
  const [isOpenCondition, setIsOpenCondition] = useState(false);
  const [isOpenConditionPanel, setIsOpenConditionPanel] = useState(false);
  const [midTableData, setMidTableData] = useState([]);
  const [completeRun, setCompleteRun] = useState(false);
  const [midDataTablePro, setMidDataTablePro] = useState(midDataTable);
  const [rows, setRows] = useState([]);
  const [blueColour, setBlueColour] = useState(false);
  const [jobs, setJobs] = useState([]);

  const handleClick = () => {
    setShowNewDiv(!showNewDiv);
  };
  const [utilitySetTables, setUitilitySetTables] = useState(
    dUtilitySetSingleEntry
  );
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTableItem, setSelectedTableItem] = useState({});
  const onTableRowClick = (oEvent) => {
    if (oEvent.target.getAttribute("data-actionable")) {
      const index = oEvent.currentTarget.getAttribute("data-item");
      setSelectedTableItem(utilitySetTables.tables[index]);
    }
  };
  const handleClickCondition = () => {
    // for mid table
    setIsOpenCondition(!isOpenCondition);
  };
  const handleClickConditionPanel = () => {
    // for condition panel
    setIsOpenConditionPanel(!isOpenConditionPanel);
  };
  const handleDeleteMidTable = (id) => {
    // console.log("yes worked")
    // const updatedData = midDataTable.filter((item) => item.id !== id);
    // setMidTableData(updatedData);
    const updatedMidDataTable = midDataTablePro.filter(
      (item) => item.id !== id
    );
    setMidDataTablePro(updatedMidDataTable);
  };
  const afterRefresh = () => {
    setLoading(true);
    setTimeout(() => {
      setCompleteRun(true);
      setLoading(false);
    }, 2000);
  };
  const handleView = (id) => {
    const selectedItem = midDataTable.find((item) => item.id === id);
    if (selectedItem) {
      showToast(
        `ID: ${selectedItem.id}\nCondition Name: ${selectedItem.condition_name}\nField: ${selectedItem.field}`
      );
    }
  };

  useEffect(() => {
    getAllJObs().then((data) => {
      console.log(data);
      setJobs(data);
    });
  }, []);

  // const handleClickBack = () => {
  //   // Navigate to the new page
  //   window.location.href = "/runing";
  // };
  // const redP = 20,
  // const blueP= 30;
  // const purpleP=30;
  const handleRowClick = (id) => {
    setSelectedRow(id);
  };
  // const aTableRowsPro =[
  //   {
  //     tableName:utilitySetTables.tableName,
  //     tableId:"",
  //     entries:"",
  //     selectedFields:"",

  //   },
  //   {
  //     tableName: "we are men",
  //     "Job Name2":"mn",
  //     "Job Name3": "pp",
  //     "Job Name4":"po"

  //   }
  // ]
  const aHeaders = [
    {
      name: "Table Name",
      field: "tableName",
      type: "text",
      width: "11%",
      accessor: "name",
    },
    {
      name: "tableId",
      field: "tableId",
      type: "text",
      width: "12%",
    },
    {
      name: "entries",
      field: "entries",
      type: "text",
      width: "10%",
    },
    {
      name: "Fields",
      field: "selectedFields",
      type: "text",
      width: "51%",
    },
    {
      name: "Status",
      field: "status",
      type: "text",
      width: "20%",
    },
  ];
  console.log(utilitySetTables);
  const aTableRows = utilitySetTables.tables.map((row, index) => {
    return (
      <tr key={row.tableId} data-item={index} onClick={onTableRowClick}>
        <td
          className={
            selectedRow === row.tableId
              ? "highlighted font-4"
              : "ano-col s font-4"
          }
          data-actionable={true}
          onClick={() => handleRowClick(row.tableId)}
        >
          {row.tableName}
        </td>
        <td className="Id-col font-4" data-actionable={true}>
          {row.tableId}
        </td>
        <td className="entries-col font-4" data-actionable={true}>
          {row.entries / 1000}k
        </td>
        <td className="field-col font-4" data-actionable={true}>
          {row.selectedFields}
        </td>
        <td data-actionable={true}>
          {/* <BsFilter color="#667085" />{" "} */}
          {completeRun ? (
            <MultiColourProgressBar PurpleP={95} redP={5} blueP={0} gryP={0} />
          ) : (
            <MultiColourProgressBar
              redP={row.error}
              blueP={row.running}
              PurpleP={row.success}
              gryP={100 - row.error + row.running + row.success}
            />
          )}
        </td>
      </tr>
    );
  });
  const aFieldsTableRows =
    selectedTableItem.columns &&
    selectedTableItem.columns.map((row, index) => {
      return (
        <tr key={row.columnName}>
          <td className="data-1st-col">
            <div className="ano-col s font-4">{selectedTableItem.tableId}</div>
            {/* <div className="text s font-4">{selectedTableItem.tableName}</div> */}
          </td>
          {/* <td className="Id-col">{row.columnName}</td> */}
          <td className="data-2nd-col">
            <div className="dash s"> - </div>
            <div className="col-data-2nd">
              <div className="d1 s font-4">
                {row.columnName}{" "}
                <span className="text s font-4">Text(100)</span>
              </div>
              <div className="d2 font-4">{row.description}</div>
            </div>
          </td>
          <td>
            <AiOutlineEye color="#667085" />
          </td>
          <td className="ano-col font-4">{row.utilityParameter}</td>
          <td className="ano-col font-4">{row.conditions}</td>
          {completeRun ? (
            <td className="ano-col font-4">Finished</td>
          ) : (
            <td className="ano-col font-4">{row.status}</td>
          )}
        </tr>
      );
    });
  let navigate = useNavigate();
  return (
    <div className="running">
      <div className="main">
        <div className="header-runing">
          <div className="head-btn-1">
            <button
              type=" button"
              className="Jobs font-5"
              onClick={() => navigate(-1)}
            >
              <BiArrowBack /> Jobs
            </button>
          </div>
          <div className="head-title s font-5">
            Employees Monthly Training Cleanup
          </div>
          <div className="head-btn-2 gap-3">
            {completeRun ? (
              <>
                <button className="btn-purple">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_7363_20847)">
                      <path
                        d="M4.16675 12.4993H3.33341C2.89139 12.4993 2.46746 12.3238 2.1549 12.0112C1.84234 11.6986 1.66675 11.2747 1.66675 10.8327V3.33268C1.66675 2.89065 1.84234 2.46673 2.1549 2.15417C2.46746 1.84161 2.89139 1.66602 3.33341 1.66602H10.8334C11.2754 1.66602 11.6994 1.84161 12.0119 2.15417C12.3245 2.46673 12.5001 2.89065 12.5001 3.33268V4.16602M9.16675 7.49935H16.6667C17.5872 7.49935 18.3334 8.24554 18.3334 9.16602V16.666C18.3334 17.5865 17.5872 18.3327 16.6667 18.3327H9.16675C8.24627 18.3327 7.50008 17.5865 7.50008 16.666V9.16602C7.50008 8.24554 8.24627 7.49935 9.16675 7.49935Z"
                        stroke="white"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7363_20847">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{" "}
                  Duplicate
                </button>
              </>
            ) : (
              <>
                <div className="refresh-p gap-3">
                  <button className="btn-purple" onClick={afterRefresh}>
                    <BiRefresh /> Refresh{" "}
                  </button>
                  <button
                    type=" button"
                    className="btn-low-purple font-6"
                    onClick={() => navigate("/edit")}
                  >
                    <BiRectangle color="#6941C6" /> Stop
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="section-runing">
          <div className="sec-1-run">
            <div className="part1">
              <p className="top-head font-4">
                Processing 821,938 / 1,269,000 Records
              </p>
              <div className="pro-bar">
                {completeRun ? (
                  <CircleProgressBar
                    size={190}
                    progress={93}
                    trackWidth={15}
                    indicatorWidth={15}
                    circleCount={1}
                    indicatorColor={`#6941C6`}
                    innerCircleRadiusSpace={20}
                    trackColor={"#E31B54"}
                    // innerCircleIndicatorColors={[`#9E77ED`, `#E31B54`]}
                    innerCircleIndicatorProgress={[25, 6]}
                  />
                ) : (
                  <CircleProgressBar
                    size={190}
                    progress={40}
                    trackWidth={15}
                    indicatorWidth={15}
                    circleCount={3}
                    indicatorColor={`#6941C6`}
                    innerCircleRadiusSpace={20}
                    innerCircleIndicatorColors={[`#9E77ED`, `#E31B54`]}
                    innerCircleIndicatorProgress={[25, 6]}
                  />
                )}
              </div>
            </div>
            <div className="part2">
              <div className="part-2-1">
                <div className="head-text-1 s font-4">Run Name</div>
                <div className="part-2-subtext">
                  <div className="sub-text-1 s font-6">
                    Employees Monthly Training Cleanup{" "}
                  </div>
                  <div className="sub-in s font-6" onClick={handleClick}>
                    Show Definitions
                  </div>
                </div>
              </div>
              <div className="part-2-1 pt-4">
                <div className="head-text-1 s font-4">Run Type</div>
                <div className="part-2-subtext">
                  <div className="sub-text-1 s font-4">SAP 4/HANA </div>
                </div>
              </div>
              <div className="part-2-1 pt-4">
                <div className="head-text-1 s font-4">Started</div>
                <div className="part-2-subtext">
                  <div className="sub-text-1 s font-4">Olivia Rhye </div>
                  <div className="sub-in-2 s font-4" onClick={handleClick}>
                    11:23AM, 12 Dec 2022
                  </div>
                </div>
              </div>
              <div className="part-2-1 pt-4">
                <div className="head-text-1 s font-4">Finished</div>
                <div className="part-2-subtext">
                  <div className="sub-text-1 s font-4">Part 1 </div>
                  <div className="sub-in-2 s font-5">
                    {completeRun ? "Fineshed" : "Running"}{" "}
                  </div>
                  <div className="sub-in-2 s font-4">
                    {!completeRun && "2 min 30 sec"}
                  </div>
                </div>
                <div className="part-2-subtext">
                  <div className="sub-text-1 s font-4">Part 2 </div>
                  <div className="sub-in-2 s font-5">
                    {completeRun ? "Fineshed" : "Running"}{" "}
                  </div>
                  {/* <div className='sub-in-2 s font-4' onClick={handleClick}>2 min 30 sec</div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="sec-2">
            <div className="sec-2">
              <div className="table-content-step-2">
                <div className="table-header">
                  <div className="rowone">
                    <div className=" col21 s font-4">
                      {utilitySetTables.tables.length} Tables
                    </div>
                    <div className=" col101 step-two-header">
                      <button type="button" className="btnSort font-6">
                        Sort: Table Names A-Z <RiArrowDropDownLine />{" "}
                      </button>

                      <button type="button" className=" btnFilters font-6 ">
                        <BiFilter></BiFilter> Filters
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-content">
                  <table style={{ width: "100%" }} className="responsive">
                    <tbody>
                      <tr>
                        <th style={{ width: "10%" }} className="font-5">
                          Table Name
                        </th>
                        <th style={{ width: "10%" }} className="font-5">
                          Table ID
                        </th>
                        <th style={{ width: "10%" }} className="font-5">
                          # Entries
                        </th>
                        <th style={{ width: "60%" }} className="font-5">
                          Fields
                        </th>
                        <th style={{ width: "5%" }} className="font-5">
                          Status
                        </th>
                        {/* <th style={{ width: '10%' }}></th> */}
                        {/* <th style={{ width: "5%" }}>Remove</th> */}
                      </tr>
                      {aTableRows}
                    </tbody>
                  </table>
                  {/* <TablePro
                  columns={aHeaders}
                  data={utilitySetTables.tables}
                  minRows={5}
                  refresh={true}
                  refreshHandler={utilitySetTables}
                   /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="section-mid ">
            <div className="mid-line-one">
              <div className="left-a">
                <div className="text font-4">Conditions</div>
              </div>
              <div className="right-a">
                {!completeRun && (
                  <div className="right">
                    {isOpenCondition && (
                      <button
                        className="btn-add-con font-6"
                        onClick={handleClickConditionPanel}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10.0001 4.16797V15.8346M4.16675 10.0013H15.8334"
                            stroke="#344054"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        Add a Condition
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="mid-line-tab">
              <div className="midle-line">
                <div
                  className="cursor-pointer left"
                  onClick={handleClickCondition}
                >
                  <div className="circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM12 10V5.33333C12 4.97971 11.8595 4.64057 11.6095 4.39052C11.3594 4.14048 11.0203 4 10.6667 4H8.66667M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6ZM4 6V14"
                        stroke="white"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="text1-util font-4">
                    Anonymize records where all conditions are met
                  </div>
                  <div className="font-4 text2-util text-condition ">
                    Client 100 Only
                  </div>
                  <div className="font-4 text3-util text-condition ">
                    Exclude Non-GDPR Countries
                  </div>
                  <div className="font-4 text4-util text-condition ">
                    Customer Address Only
                  </div>
                  <div className="font-4 text5-util text-condition ">
                    BP Customer Only
                  </div>
                  <div className="font-4 text5-util text-condition ">
                    Family Size 11 Only
                  </div>
                  <div className="cursor-pointer text6-util">
                    {isOpenCondition ? (
                      <>
                        <RiArrowDropDownLine size={24} />
                      </>
                    ) : (
                      <>
                        <RiArrowDropUpLine size={24} />
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* mid table  */}
              {isOpenCondition && (
                <div>
                  <div className="table-content ">
                    <table
                      style={{ width: "100%" }}
                      className="responsive table  "
                    >
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }} className="font-5">
                            Condition Name & Category{" "}
                          </th>
                          <th style={{ width: "10%" }} className="font-5">
                            Field
                          </th>
                          <th style={{ width: "40%" }} className="font-5">
                            Rule & Value
                          </th>
                          <th style={{ width: "20%" }} className="font-5">
                            Table
                          </th>
                          <th style={{ width: "10%" }} className="font-5">
                            Action
                          </th>
                        </tr>

                        {/* {rows.map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {row.map((value, colIndex) => (
                              <>
                                <td key={colIndex}>{value}</td>
                               
                              </>

                            ))}
                          </tr>
                        ))
                        } */}
                        {rows.map((value, rowIndex) => (
                          <tr>
                            <>
                              {/* <td key={colIndex[0]}>{value}</td> */}

                              <td
                                style={{ width: "15%" }}
                                className="font-4 mid-table-col-1"
                              >
                                {value[0]}
                              </td>
                              <td style={{ width: "10%" }} className="font-4 ">
                                <div className="mid-table-col-2">
                                  <div className="col-2-text1 font-4">
                                    {value[2]}
                                  </div>
                                  <div className="col-2-text2 font-4">
                                    {value[2]}
                                  </div>
                                </div>
                              </td>
                              <td style={{ width: "40%" }} className="font-4">
                                <div className="mid-table-col-3">
                                  {/* <div className="circle"><BiListUl /></div> */}
                                  <div className="text-col3 font-4">
                                    <div className="t-c-1 font-4">
                                      {value[3]} {value[4]} : {value[5]}
                                    </div>
                                    <div className="t-c-2 font-4">
                                      {value[4]}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td
                                style={{ width: "20%" }}
                                className="font-4 mid-table-col-4"
                              >
                                {value[1]}
                              </td>
                              <td
                                style={{ width: "10%" }}
                                className="font-4 mid-table-col-5"
                              >
                                <button className="action-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M0.833252 9.9987C0.833252 9.9987 4.16658 3.33203 9.99992 3.33203C15.8333 3.33203 19.1666 9.9987 19.1666 9.9987C19.1666 9.9987 15.8333 16.6654 9.99992 16.6654C4.16658 16.6654 0.833252 9.9987 0.833252 9.9987Z"
                                      stroke="#667085"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9.99992 12.4987C11.3806 12.4987 12.4999 11.3794 12.4999 9.9987C12.4999 8.61799 11.3806 7.4987 9.99992 7.4987C8.61921 7.4987 7.49992 8.61799 7.49992 9.9987C7.49992 11.3794 8.61921 12.4987 9.99992 12.4987Z"
                                      stroke="#667085"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>{" "}
                                <button className="action-btn">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_6470_50043)">
                                      <path
                                        d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                                        stroke="#667085"
                                        strokeWidth="1.66667"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_6470_50043">
                                        <rect
                                          width="20"
                                          height="20"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>{" "}
                              </td>
                            </>
                          </tr>
                        ))}
                        {midDataTablePro.map((item) => (
                          <tr key={item.id}>
                            <td
                              style={{ width: "15%" }}
                              className="font-4 mid-table-col-1"
                            >
                              {item.condition_name}
                            </td>
                            <td style={{ width: "10%" }} className="font-4 ">
                              <div className="mid-table-col-2">
                                <div className="col-2-text1 font-4">
                                  {item.field}
                                </div>
                                <div className="col-2-text2 font-4">
                                  {item.Field_dis}
                                </div>
                              </div>
                            </td>
                            <td style={{ width: "40%" }} className="font-4">
                              <div className="mid-table-col-3">
                                {/* <div className="circle"><BiListUl /></div> */}
                                <div className="text-col3 font-4">
                                  <div className="t-c-1 font-4">
                                    {item.rule}
                                  </div>
                                  <div
                                    className={
                                      blueColour
                                        ? "t-c-2 font-4"
                                        : "font-color t-c-2 font-4"
                                    }
                                  >
                                    {item.rule_dis}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{ width: "20%" }}
                              className="font-4 mid-table-col-4"
                            >
                              {item.table}
                            </td>
                            <td
                              style={{ width: "10%" }}
                              className="font-4 mid-table-col-5"
                            >
                              <button
                                className="action-btn"
                                onClick={() => handleView(item.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M0.833252 9.9987C0.833252 9.9987 4.16658 3.33203 9.99992 3.33203C15.8333 3.33203 19.1666 9.9987 19.1666 9.9987C19.1666 9.9987 15.8333 16.6654 9.99992 16.6654C4.16658 16.6654 0.833252 9.9987 0.833252 9.9987Z"
                                    stroke="#667085"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.99992 12.4987C11.3806 12.4987 12.4999 11.3794 12.4999 9.9987C12.4999 8.61799 11.3806 7.4987 9.99992 7.4987C8.61921 7.4987 7.49992 8.61799 7.49992 9.9987C7.49992 11.3794 8.61921 12.4987 9.99992 12.4987Z"
                                    stroke="#667085"
                                    strokeWidth="1.66667"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>{" "}
                              </button>{" "}
                              <button
                                className="action-btn"
                                onClick={() => handleDeleteMidTable(item.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_6470_50043)">
                                    <path
                                      d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                                      stroke="#667085"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_6470_50043">
                                      <rect
                                        width="20"
                                        height="20"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="table-add ">
            {aFieldsTableRows && aFieldsTableRows.length > 0 && (
              <div className="inner-table pt-1">
                <div className="table-content-step-2">
                  <div className="table-header">
                    <div className="rowone">
                      <div className=" col21 s font-4">
                        {aFieldsTableRows.length} Fields
                      </div>
                      <div className=" col101 step-two-header">
                        <button type="button" className=" btnFilters font-6">
                          <BiFilter></BiFilter> Filters
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-content">
                    <table
                      style={{ width: "100%" }}
                      className="responsive table"
                    >
                      <tbody>
                        <tr>
                          <th style={{ width: "10%" }} className="font-5">
                            Table
                          </th>
                          <th style={{ width: "35%" }} className="font-5">
                            Field Name
                          </th>
                          <th style={{ width: "5%" }} className="font-5">
                            Preview
                          </th>
                          <th style={{ width: "10%" }} className="font-5">
                            Utility Parameter
                          </th>
                          {/* <th style={{ width: '10%' }} className='font-5' ></th> */}
                          <th style={{ width: "15%" }} className="font-5">
                            Conditions
                          </th>
                          <th style={{ width: "30%" }} className="font-5">
                            Status
                          </th>
                        </tr>
                        {aFieldsTableRows}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="def-run-pro">
          {showNewDiv && (
            <div>
              <div className="panel-def-p">
                <div className="header-def ">
                  <div className="col9 s font-5">Definitions Preview</div>
                  <div className="col3 s" onClick={handleClick}>
                    {" "}
                    <RxCross2 color="black" />{" "}
                  </div>
                </div>
                <div className="section-p">
                  <div className="sec-1-p pt-4">
                    <div className="head-text-p s font-4">Execution Set</div>
                    <div className="sub-1 font-4 s font-4">Name</div>
                    <div className="sub-2 font-4 s">PROD w/Script</div>
                  </div>
                  <div className="sec-1-p pt-4">
                    <div className="head-text-p s">Step 1</div>
                    <div className="sub-1 font-4 s">Configuration Set Name</div>
                    <div className="sub-2 font-4 s">L3C</div>
                  </div>
                  <div className="sec-2-p pt-3">
                    <div className="sub-1 font-4 s">Host Name</div>
                    <div className="sub-2 font-4 s">101.0.63.255</div>
                  </div>
                  <div className="sec-2-p pt-3">
                    <div className="sub-1 font-4 s">Username</div>
                    <div className="sub-2 font-4 s">Admin</div>
                  </div>
                  <div className="sec-2-p pt-3">
                    <div className="sub-1 font-4 s">Utility Set Name</div>
                    <div className="sub-2 font-4 s">Utility_set_Demo_1</div>
                  </div>
                  <div className="sec-2-p pt-3">
                    <div className="sub-1 font-4 s">
                      Selected for Anonymization
                    </div>
                    <div className="sub-2 font-4 s">
                      9 Tables (Accounts, Addresses, Bonuses, Hierarchy,
                      Salaries, Timesheets) 57 Fields
                    </div>
                  </div>
                  <div className="sec-1-p pt-4">
                    <div className="head-text-p s">Step 2</div>
                    <div className="sub-1 font-4 s">Configuration Set Name</div>
                    <div className="sub-2 font-4 s">PROD</div>
                  </div>
                  <div className="sec-2-p pt-3">
                    <div className="sub-1 font-4 s">Host Name</div>
                    <div className="sub-2 font-4 s">12.12.12.12:90</div>
                  </div>
                  <div className="sec-2-p pt-3">
                    <div className="sub-1 font-4 s">Username</div>
                    <div className="sub-2 font-4 s">Admin</div>
                  </div>
                </div>
                <footer className="footer-sh">
                  {/* <hr  className='footer-line' /> */}
                  {/* <button className='save s'>Save & Use</button> */}
                  <button className="cancel s font-5" onClick={handleClick}>
                    <RxCross2 /> Cancel
                  </button>
                </footer>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Runing;
