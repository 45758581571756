import React, { useEffect, useState } from "react";
import "./NewRun.scss";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { BsArrowRight } from "react-icons/bs";
import { isNumber } from "chart.js/helpers";
import { useNewRunContext } from "../../hooks/NewRunContext";
import ExecutionDefinition from "../../components/ExecutionDefinition/ExecutionDefinition";
import { useUserContext } from "../../hooks/UserContext";
import { useTranslation } from "react-i18next";
import {
  createCollaborationGroup,
  getCollaborationGroups,
} from "../../hooks/apis/useAppSafeApi";
import Select from "react-select";

const NewRun = () => {
  const { t } = useTranslation("newrun");
  const [form1Active, setForm1Active] = useState(true);
  const [form2Active, setForm2Active] = useState(false);
  const { data, executionSets, updateData } = useNewRunContext();
  const { showToast } = useUserContext();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOptionExe, setSelectedOptionExe] = useState("");
  const [collaborationGroups, setCollaborationGroups] = useState([]);
  const navigate = useNavigate();

  // State for new collaboration group form
  const [newGroup, setNewGroup] = useState({
    projectName: "",
    projectDescription: "",
    businessArea: "",
    industryArea: "",
    projectAttributes: [],
  });
  const handleAddAttribute = () => {
    setNewGroup((prev) => ({
      ...prev,
      projectAttributes: {
        ...prev.projectAttributes,
        [`key${Object.keys(prev.projectAttributes).length + 1}`]: "",
      },
    }));
  };

  // Remove attribute
  const handleRemoveAttribute = (key) => {
    const { [key]: _, ...remainingAttributes } = newGroup.projectAttributes;
    setNewGroup((prev) => ({
      ...prev,
      projectAttributes: remainingAttributes,
    }));
  };
  const handleAttributeChange = (key, value) => {
    setNewGroup((prev) => ({
      ...prev,
      projectAttributes: {
        ...prev.projectAttributes,
        [key]: value,
      },
    }));
  };

  const handleNewGroupChange = (e) => {
    const { name, value } = e.target;
    setNewGroup((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitNewGroup = async () => {
    if (Object.keys(newGroup.projectAttributes).length === 0) {
      showToast("Project attributes are not passed.");
      return; // Exit the function if project attributes are missing
    }

    try {
      const response = await createCollaborationGroup(newGroup);
      if (response && response.success) {
        setCollaborationGroups((prevGroups) => [...prevGroups, response]);
        setSelectedOptionExe(response.id);
        updateData({ ...data, collaborationGroup: response });

        showToast("New collaboration group created and selected.");
        closePopup();
      } else {
        showToast("Failed to create the collaboration group.");
      }
    } catch (error) {
      console.error("Error creating new collaboration group:", error);
      showToast(error.message);
    }
  };

  useEffect(() => {
    if (executionSets && executionSets.length > 0) {
      let current = "";
      for (let i = 0; i < executionSets.length; i++) {
        let executionSet = executionSets[i];
        if (
          data.executionSet &&
          data.executionSet.executionSetId === executionSet.executionSetId
        ) {
          current = i;
          break;
        }
      }
      setSelectedOptionExe(current);
    }
  }, []);

  useEffect(() => {
    const fetchCollaborationGroups = async () => {
      try {
        const response = await getCollaborationGroups();
        if (Array.isArray(response)) {
          setCollaborationGroups(response);
        } else {
          console.error(
            "Failed to fetch collaboration groups:",
            response.message
          );
        }
      } catch (error) {
        console.error("Error fetching collaboration groups:", error);
      }
    };

    fetchCollaborationGroups();
  }, []);

  const firstHandleClickDisabledForm = () => {
    setForm1Active(true);
    setForm2Active(false);
  };

  const handleOptionChangeExecution = (event) => {
    let value = event;
    setSelectedOptionExe(value);

    if (isNumber(value)) {
      updateData({ ...data, executionSet: executionSets[value] });

      return;
    }
    updateData({ ...data, executionSet: null });
  };

  const handleSelectChange = (selectedOption) => {
    let value = selectedOption.value;
    setSelectedOptionExe(value);

    // Find the selected group based on the value
    const selectedGroup = collaborationGroups.find(
      (group) => group.id === value
    );

    if (selectedGroup) {
      updateData({ ...data, seed: selectedGroup?.id });
    } else {
      console.log("Group not found or no value available.");
    }

    if (value === "create-new-group") {
      setShowPopup(true); // Show popup to create a new collaboration group
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // console.log("data", data.executionSet.executionSetId);

  const onNextClick = (e) => {
    e.preventDefault();

    if (data?.runName === "") {
      showToast("Run name is empty");
      return;
    }
    if (!data?.executionSet) {
      showToast("Execution set is not selected");
      return;
    }
    if (!data?.seed) {
      showToast("Collaboration group is not selected");
      return;
    }
    navigate("/edit", { state: data });
  };

  const availableConditions = executionSets.map((set, index) => ({
    value: index,
    label: set.executionSetName,
  }));

  const selectDropdownStyle = {
    width: "100%",
    fontSize: "16px",
    lineHeight: "24px",
    color: "var(--gray-500, #667085)",
    display: "flex",
    padding: "8px 12px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid var(--gray-300, #D0D5DD)",
    background: "var(--White, #FFF)",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  };

  const availableGroups = collaborationGroups.map((group) => ({
    value: group.id,
    label: group.projectName,
  }));

  const createNewGroupOption = {
    value: "create-new-group",
    label: "Create new collaboration group",
  };

  const allOptions = [...availableGroups, createNewGroupOption];

  return (
    <div>
      <div className="main-run">
        <div className="">
          <p className="font-5 heading1">{t("newrun")}(1/2)</p>
        </div>
        <div className="section-run">
          <div className="sec-01">
            <p className="head-sec font-4">{t("runsetting")}</p>
            <form className="form">
              <div className="form-group">
                <label
                  htmlFor="exampleInputtext1"
                  className="level-text font-5"
                >
                  {t("runname")} *
                </label>
                <input
                  value={data.runName}
                  onChange={(e) => {
                    updateData({ ...data, runName: e.target.value });
                  }}
                  type="text"
                  className="form-control input-box-common font-4"
                  id="exampleInputtext1"
                  aria-describedby="textHelp"
                  placeholder="SharePoint"
                />
              </div>
              <div action="">
                <div
                  className="form-group"
                  onClick={firstHandleClickDisabledForm}
                >
                  <label
                    htmlFor="cars"
                    className={
                      form1Active
                        ? "lable-sel-option font-5"
                        : "disabled lable-sel-option font-5"
                    }
                  >
                    {t("executionset")} *
                  </label>
                  <Select
                    className="font-4"
                    options={availableConditions}
                    value={availableConditions.find(
                      (option) => option.value === selectedOptionExe
                    )}
                    onChange={(selectedOption) =>
                      handleOptionChangeExecution(selectedOption.value)
                    }
                    placeholder={
                      availableConditions.length === 0
                        ? "No Options"
                        : !selectedOptionExe
                        ? "Use Default"
                        : "Select..."
                    }
                    isMulti={false} // or `true` if multiple selections are allowed
                    menuPlacement="auto"
                    styles={selectDropdownStyle}
                    menuPortalTarget={document.body}
                  />
                </div>
              </div>
              <div action="">
                <div className="form-group">
                  <label
                    htmlFor="collaborationGroup"
                    className={
                      form1Active
                        ? "lable-sel-option font-5"
                        : "disabled lable-sel-option font-5"
                    }
                  >
                    Collaboration group
                  </label>
                  <Select
                    id="collaborationGroup"
                    className="font-4"
                    options={allOptions}
                    onChange={handleSelectChange}
                    placeholder="Select a collaboration group"
                    menuPlacement="auto"
                    styles={selectDropdownStyle}
                    menuPortalTarget={document.body}
                  />
                </div>

                {/* Modal Popup */}
                {showPopup && (
                  <div className="popup-overlay">
                    <div className="popup-content">
                      <h2>Create New Collaboration Group</h2>
                      <form>
                        {/* Existing form fields */}
                        <div className="form-group">
                          <label>Project Name</label>
                          <input
                            type="text"
                            name="projectName"
                            value={newGroup.projectName}
                            onChange={handleNewGroupChange}
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Project Description</label>
                          <input
                            type="text"
                            name="projectDescription"
                            value={newGroup.projectDescription}
                            onChange={handleNewGroupChange}
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Business Area</label>
                          <input
                            type="text"
                            name="businessArea"
                            value={newGroup.businessArea}
                            onChange={handleNewGroupChange}
                            className="form-control"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Industry Area</label>
                          <input
                            type="text"
                            name="industryArea"
                            value={newGroup.industryArea}
                            onChange={handleNewGroupChange}
                            className="form-control"
                            required
                          />
                        </div>

                        {/* Dynamic project attributes */}
                        <div className="form-group">
                          <label>Project Attributes</label>
                          {Object.entries(newGroup.projectAttributes).map(
                            ([key, value], index) => (
                              <div key={index} className="attribute-pair">
                                <input
                                  type="text"
                                  placeholder="Key"
                                  value={key}
                                  onChange={(e) => {
                                    const updatedAttributes = {
                                      ...newGroup.projectAttributes,
                                    };
                                    delete updatedAttributes[key];
                                    updatedAttributes[e.target.value] = value;
                                    setNewGroup((prev) => ({
                                      ...prev,
                                      projectAttributes: updatedAttributes,
                                    }));
                                  }}
                                  className="form-control attribute-key"
                                />
                                <input
                                  type="text"
                                  placeholder="Value"
                                  value={value}
                                  onChange={(e) =>
                                    handleAttributeChange(key, e.target.value)
                                  }
                                  className="form-control attribute-value"
                                />
                                <button
                                  type="button"
                                  onClick={() => handleRemoveAttribute(key)}
                                  className="remove-attribute"
                                >
                                  Remove
                                </button>
                              </div>
                            )
                          )}
                          <button type="button" onClick={handleAddAttribute}>
                            Add Attribute
                          </button>
                        </div>

                        <div className="helloButton">
                          <button type="button" onClick={handleSubmitNewGroup}>
                            Create
                          </button>
                          <button type="button" onClick={closePopup}>
                            Close
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>

          <div className="sec-2">
            {form1Active ? (
              data.executionSet ? (
                <ExecutionDefinition executionSet={data.executionSet} />
              ) : (
                <div>{t("desc")}</div>
              )
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="footer-run">
          <hr className="footer-line" />
          <div className="clearfix">
            <div className="float-left">
              <button className="res-back-btn font-5">
                <RxCross2 color="#344054" /> {t("Cancel")}
              </button>
            </div>
            <div className="float-right">
              <button className="btn-purple font-6" onClick={onNextClick}>
                <BsArrowRight color="#FFFFFF" /> {t("Next")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRun;
