import React, { useEffect, useState } from 'react'
import { getUtilityPiiHistory, getUtilityPiiHistoryById } from '../../../hooks/apis/useAppSafeApi';
import TablePro from '../../../components/newTable/TablePro';
import useAppContext from '../../../hooks/useAppContext';
import DetailTablesData from './DetailTablesData';
import { FiEye } from 'react-icons/fi';

const PiiHistory = () => {
    const [piiHistory, setPiiHistory] = useState([] || '')
    const [filteredPiiHistory, setFilteredPiiHistory] = useState([] )
    const [currentTableForViewDetails, setCurrentTableForViewDetails] = useState({ id: "", name: "", type: "", utilitySetId:"" })
    const { popupModel } = useAppContext();

    const getUserPiiHistory = async () => {
        setPiiHistory("Loading...")
        await getUtilityPiiHistory().then(res => {
            setPiiHistory(res)
            setFilteredPiiHistory(res)
        }).catch(err => {
            setPiiHistory(err.message)
        })
    }
    useEffect(() => {
        getUserPiiHistory();
    }, []);
    const getDetailsData = (id) => {

    }

    const piiColumn = [
        {
            name: 'Run Id',
            field: 'runId',
            type: "customControl",
            width: "5%",
            fnRenderCustomControl: (item) => {
                return <div className='cursor-pointer font-5' onClick={async () => await setCurrentTableForViewDetails({ id: item.id, name: item.utilitySetName, type: item.type, utilitySetId: item.utilitySetId })}>{item.runId}</div>
            }
        },
        {
            name: "Type",
            field: 'type',
            type: 'text'
        },
        {
            name: 'UtilitySet Name',
            field: 'utilitySetName',
            type: 'text',
            width: '5%'
        },

        {
            name: 'ConfigSet Name',
            field: "configSetName",
            type: "text",
            width: '5%'
        },


        {
            name: 'Schema Name',
            field: 'schemaName',
            type: 'text',
            width: '5%'
        },

        {
            name: 'Created At',
            field: 'createdAt',
            type: 'Date',
            width: '5%'
        },
        {
            name: 'Description',
            field: 'utilitySetDescription',
            type: 'text',
            // width: '5%'
        },
        {
            name: "Action",
            type: "customControl",
            width: "5%",
            fnRenderCustomControl: (item) => {
                return <div className='cursor-pointer font-5' onClick={async () => await setCurrentTableForViewDetails({ id: item.id, name: item.utilitySetName, type: item.type, utilitySetId: item.utilitySetId })}><FiEye /></div>
            }
        }
    ]
    const onSearch = (keyWord) => {
        if (!keyWord || keyWord === "") {
            setFilteredPiiHistory(piiHistory);
            return;
        }

        const lowerCaseKeyword = keyWord.toLowerCase();

        setFilteredPiiHistory(
            piiHistory.filter((rowItem) =>{
                return Object.values(rowItem).some((value) =>
                    String(value).toLowerCase().includes(lowerCaseKeyword)
                );
            }
            )
        );
    };

    const displayTable = () => {
        if (!piiHistory) {
            return <div className='font-5'>No data available</div>;
        }

        if (typeof piiHistory !== 'object') {
            return <div className='font-5'>{piiHistory}</div>;
        }

        if (piiHistory.length === 0) {
            return <div className='fonr-5'>No records found</div>;
        }
        return <TablePro columns={piiColumn} data={filteredPiiHistory.sort(
            (a, b) =>
                new Date(b.createdAt) - new Date(a.createdAt)
        )} minRows={5}
            showRecords={true}
            search={true}
            onSearch={onSearch}
        />
    }
    return (<>
        {currentTableForViewDetails.id !== "" && (
            <div className="backdropPopupStyle">
                <DetailTablesData data={currentTableForViewDetails} onCancel={setCurrentTableForViewDetails} />
            </div>
        )}
        {displayTable()}
    </>
    )
}

export default PiiHistory