import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { serverAxios } from "../../utils/AxiosUtils";
import { UserContext, useUserContext } from "../../hooks/UserContext";
import { useTranslation } from "react-i18next";

export const AddParamsAndCondition = ({
  utilitySetId,
  tableId,
  isPanel,
  refresh,
  refreshParam,
}) => {
  const { t } = useTranslation("utility");

  const { showToast } = useUserContext();
  const [columnName, setColumnName] = useState("");
  const [error, setError] = useState(false)
  const onSaveAddTableData = () => {
    if(columnName===""){
      setError(true)
      return;
    }
    const formData = new FormData();
    formData.append("column_name", columnName);
    serverAxios.appSafeAxios
      .post(
        `utility-set/${utilitySetId}/tables/${tableId.id}/columns`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        refresh();
        refreshParam(tableId.utilitySetId, tableId.id);
        showToast(res.data.message, "success");
        setColumnName("")
      })
      .catch((err) => {
        showToast(err.message, "fail");
      });
  };

  return (
    <>
      <div className="panel-utility">
        <div className="panel-screen">
          <div className="header-panel">
            <div className="left font-5"> {t("Add Column")}</div>
            <div className="right">
              <RxCross1
                className="cross cursor-pointer"
                onClick={() => isPanel(false)}
              />
            </div>
          </div>
          <div className="field-group-section-panel">
            <div className="field-section-panel">
              <label className="level-text font-5">
                {t("Column")} {t("Name")} *
              </label>
              <input
                type="text"
                name="tableName"
                value={columnName}
                onChange={(e) => setColumnName(e.target.value)}
                className=" width-of-panel-input input-field-shadow font-4 "
                placeholder={t("Name...")}
              />{error&& columnName===""&&<p className="text-danger font-4 fs-6">Column Name is required.</p>}
            </div>
          </div>
          <div className="footer-panel">
            <button className="btn-purple font-6" onClick={onSaveAddTableData}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.1667 17.5V10.8333H5.83333V17.5M5.83333 2.5V6.66667H12.5M15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H13.3333L17.5 6.66667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5Z"
                  stroke="#FCFAFF"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
