import React, { useContext, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Select from "react-select";
import { getConfigSetData } from "../../../hooks/apis/useAppSafeApi";
import {
  InputSection,
  RadioSection,
} from "../../../components/FieldSection.jsx/field";
import { UserContext, useUserContext } from "../../../hooks/UserContext";
import { serverAxios } from "../../../utils/AxiosUtils";
import { FaSave } from "react-icons/fa";
import {
  inputField_FORM_TABLE,
  inputField_FORM_TABLE_DIFFRENT_SOURCE,
  inputField_QUERY_Table,
} from "./nameSource_dummyData";
const Copy_name = ({ setCopyName, copyName, inputField, refresh }) => {
  const { setLoading } = useContext(UserContext);
  const { showToast } = useUserContext();
  const [configSets, setConfigSets] = useState([]);
  const [formData, setFormData] = useState({
    configSet: copyName.id ? copyName.id.configSet : "",
    firstName: copyName.id ? copyName.id.firstNameField : "",
    lastName: copyName.id ? copyName.id.lastNameField : "",
    selectSQL: copyName.id ? copyName.id.selectSQL : '',
    sameSource:copyName.id ?copyName.id.sameSource: true,
    jobType: copyName.id ? copyName.id.nameReplacementJobType : "FROM_TABLE",
  });
  useEffect(() => {
    getConfigSetData()
      .then((res) => {
        setConfigSets(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const optionsConfigSet = configSets.map((val) => ({
    value: val.id,
    label: val.configSetName,
  }));

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, configSet: selectedOption.value });
  };

  const handleChange = (e, radioName = null) => {
    const { value, checked } = e.target;
    let name;
    if (radioName) {
      name = radioName;
    } else name = e.target.name;
    if(radioName==="sameSource"){
        setFormData({ ...formData, [name]: checked });
    }else{
        setFormData({ ...formData, [name]: value });
    }
    
  };

  const handleSaveCopyUtil = async (e) => {
    e.preventDefault();
    const data = {
      configSetId: formData?.configSet, //r
      firstNameColumnName: formData?.firstName, //r
      lastNameColumnName: formData?.lastName, //r
      selectQuery: formData?.jobType==="FROM_TABLE"?null:formData?.selectSQL,
      firstNameTable:formData?.jobType==="FROM_TABLE"? formData?.firstNameTable:null,
      firstNameSchema:formData?.jobType==="FROM_TABLE"? formData?.firstSchema:null,
      sameSource: formData?.sameSource,
      lastNameTable:formData?.jobType==="FROM_TABLE"? (!formData?.sameSource ?formData?.lastNameTable:null):null,
      lastNameSchema: formData?.jobType==="FROM_TABLE"? (!formData?.sameSource ?formData?.lastSchema:null):null,
      nameReplacementJobType: formData?.jobType //r
    };
    setLoading(true)
    await serverAxios.appSafeAxios.post(`utils/name-replacement`, data).then(res => {
        showToast(res?.data?.message,'success')
        refresh()
    }).catch(err => {
        showToast(err?.message, "fail");
        console.error("error on Retrieve Tables:: ", err);
    }).finally(() => { setLoading(false) })
  };

  return (
    <div
      className="panel-utility pl-2 h-100"
      style={{ padding: "16px 15px 12px 12px" }}
    >
      <div className="h-100 panel-screen gap-4 d-flex flex-column">
        <div className="d-flex justify-content-between w-100">
          <h4 className="font-5">
            {" "}
            {copyName.id !== "" ? (
              <>Edit {copyName.id.projectName}</>
            ) : (
              "Add Name Source"
            )}
          </h4>
          <RxCross2
            className="level-text cursor-pointer"
            onClick={() => setCopyName({ show: false, id: "" })}
            size={25}
          />
        </div>
        <form
          className="d-flex flex-column h-100 justify-content-between"
          onSubmit={handleSaveCopyUtil}
        >
          <div className="gap-3 d-flex flex-column">
            <div className="w-75 input-section">
              <label className="level-text font-5">Configuration Set *</label>
              <Select
                options={optionsConfigSet}
                onChange={handleSelectChange}
                className="select-dropdown"
                placeholder="Select an action"
                required
              />
            </div>
            <div className="gap-4 w-75 d-flex flex-column">
              {inputField.map((i) => (
                <InputSection
                  key={i.name}
                  {...i}
                  value={formData[i.name]}
                  onChange={handleChange}
                />
              ))}
              <RadioSection
                title="Job Type"
                name="jobType"
                value1="FROM_TABLE"
                value2="FROM_SQL_QUERY"
                label1="FROM_TABLE"
                label2="FROM_SQL_QUERY"
                checked1={formData.jobType === "FROM_TABLE"}
                checked2={formData.jobType === "FROM_SQL_QUERY"}
                onChange={(e) => handleChange(e, "jobType")}
                
              />
              {formData.jobType === "FROM_TABLE" ? (
                <>
                  {inputField_FORM_TABLE.map((i) => (
                    <InputSection
                      key={i.name}
                      {...i}
                      value={formData[i.name]}
                      onChange={handleChange}
                    />
                  ))}
                  <div className="d-flex align-items-center gap-5 ">
                    <span className="font-4 d-flex gap-1">
                      {" "}
                      <input
                        type="checkbox"
                        // onClick={() => setSameConfig(!sameConfig)}
                        onChange={async (e) => {handleChange(e, e.target.name);
                        }}
                        className=" ml-4"
                        name="sameSource"
                        value={formData.sameSource}
                        checked={formData.sameSource}
                      /> Same as Source
                    </span>
                  </div>
                  {!formData.sameSource&&<>
                    {inputField_FORM_TABLE_DIFFRENT_SOURCE.map((i) => (
                    <InputSection
                      key={i.name}
                      {...i}
                      value={formData[i.name]}
                      onChange={handleChange}
                    />
                  ))}
                  </>}
                </>
              ) : (
                <>
                  {inputField_QUERY_Table.map((i) => (
                    <InputSection
                      key={i.name}
                      {...i}
                      value={formData[i.name]}
                      onChange={handleChange}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
          <button type="submit" className="btn-purple mt-3 align-self-start">
            <FaSave /> Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default Copy_name;
