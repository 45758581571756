import React, { useState, useRef, useEffect } from "react";
import Samuel from "react-markdown";
import CodeBlock from "./CodeBlock";
import "./Conversation.scss";
import { MdContentCopy } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import anonymous from "../../../images/anonymous.png";

const ChatHistory = ({ children }) => {
  const [isCopied, setIsCopied] = useState(false);
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [children]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(children.content).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000); // Reset after 2 seconds
    });
  };

  if (children.role === "system") {
    return null;
  }

  return (
    <div className="head_conversation_ai_safe">
      <div className="conversation_ai_safe">
        {children.role === "assistant" && (
          <div className="mt-2">
            <strong>Chat Response:</strong>
            {children.content.startsWith("http") ? (
              // <img
              //   alt="img"
              //   src={children.content}
              //   className="response-image"
              // />
              <a
                href={children.content}
                target="_blank"
                rel="noopener noreferrer"
                className="anchor"
              >
                Click here to view the image
              </a>
            ) : (
              <Samuel
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <CodeBlock
                        language={match[1]}
                        value={String(children).replace(/\n$/, "")}
                      />
                    ) : (
                      <code {...props} className={className}>
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {children.content}
              </Samuel>
            )}

            <div
              className={`copy-button_ai mt-2 ${isCopied ? "copied" : ""}`}
              onClick={copyToClipboard}
            >
              {isCopied ? <FaCheck /> : <MdContentCopy />}
            </div>
          </div>
        )}
        {children.role === "user" && children.originalPrompt && (
          <div className="original_prompt">
            <div>{children.originalPrompt}</div>
          </div>
        )}
        {children.role === "user" && children.content && (
          <div className="anony_ai_safe">
            <div className="img_head_ai_safe">
              <img
                src={anonymous}
                alt="Anonymous"
                className="img_conversation"
              />
            </div>
            <div className="anonymised_prompt">
              <div>{children.content}</div>
            </div>
          </div>
        )}
        <div ref={chatEndRef} /> {/* Scroll target */}
      </div>
    </div>
  );
};

export default ChatHistory;
