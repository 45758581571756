import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import {
  FiSettings,
  FiX,
  FiMoreVertical,
  FiTrash2,
  FiEdit3,
} from "react-icons/fi";
import "./AiSafe.scss";
import {
  history,
  deleteHistoryItem,
  renameHistoryItem,
} from "../../hooks/apis/aiSafe";
import { useParams } from "react-router-dom";

const SidebarAiSafe = ({ id, onSettingsClick, isOpen, onClose }) => {
  const idd = useParams();
  const [historyData, setHistoryData] = useState([]);
  const [error, setError] = useState(null);
  const [showDropdown, setShowDropdown] = useState(null);
  const [editingItemId, setEditingItemId] = useState(null);
  const [newName, setNewName] = useState("");

  // Function to fetch history, reusable in multiple places
  const fetchHistory = async () => {
    try {
      const data = await history();
      if (data.data) {
        setHistoryData(data.data.data);
      } else {
        setError("Unexpected data format");
      }
    } catch (error) {
      setError("Failed to fetch history");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, [id]);

  const handleNewChat = () => {
    window.location.assign("/ai-safe/");
  };

  const handleRenameSubmit = async (session_id) => {
    try {
      await renameHistoryItem(newName, session_id);
      await fetchHistory();
    } catch (error) {
      console.error("Failed to rename item", error);
    }
    setEditingItemId(null);
    setShowDropdown(null);
  };

  const handleDelete = async (itemId) => {
    try {
      await deleteHistoryItem(itemId);
      await fetchHistory();
      if (itemId === idd.id) {
        // Redirect to New Chat page if deleting the current session
        handleNewChat();
        window.location.assign("/ai-safe/");
      } else {
        await fetchHistory();
      }
    } catch (error) {
      console.error("Failed to delete item", error);
    }
    setShowDropdown(null);
  };

  return (
    <div className={`sidebar_ai_safe ${isOpen ? "open" : ""}`}>
      <div className="side_button_top">
        <button className="button_ai_safe" onClick={handleNewChat}>
          <FaPlus />
          New Chat
        </button>
        <button className="close_button" onClick={onClose}>
          <FiX />
        </button>
      </div>
      <div className="ai_safe_history">
        {Object.keys(historyData).length > 0 ? (
          Object.entries(historyData).map(([itemId, message]) => (
            <div
              key={itemId}
              className="comment_ai_safe_1 font-4"
              onMouseLeave={() => setShowDropdown(null)}
            >
              <div className="comment_wrapper">
                {editingItemId === itemId ? (
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    onBlur={() => handleRenameSubmit(itemId)} // Submit when losing focus
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleRenameSubmit(itemId);
                    }}
                    autoFocus
                    className="rename_input"
                  />
                ) : (
                  <a
                    className={`comment_ai font-4  ${
                      itemId === idd.id ? "highlighted" : ""
                    }`}
                    href={`/ai-safe/c/${itemId}`}
                  >
                    {message}
                  </a>
                )}

                <div
                  className="more_options"
                  onMouseEnter={() => setShowDropdown(itemId)}
                >
                  <FiMoreVertical className="more_icon" />
                  {showDropdown === itemId && (
                    <div className="dropdown_menu">
                      <div
                        className="dropdown_item"
                        onClick={() => {
                          setEditingItemId(itemId);
                          setNewName(message);
                        }}
                      >
                        <FiEdit3 />
                        Rename
                      </div>
                      <div
                        className="dropdown_item"
                        onClick={() => handleDelete(itemId)}
                      >
                        <FiTrash2 />
                        Delete
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-history">No history available</div>
        )}
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="bottom_setting">
        <div className="separator"></div>
        <div className="button_settings font-5" onClick={onSettingsClick}>
          <FiSettings />
          Settings
        </div>
      </div>
    </div>
  );
};

export default SidebarAiSafe;
