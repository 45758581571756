import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./CodeBlock"; // Ensure this component is defined correctly.
import "./Conversation.scss"; // Ensure the stylesheet is correctly linked.
import anonymous from "../../../images/anonymous.png"; // Ensure this image path is correct.
import { FaCopy, FaCheck } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";

const ConversationEntry = ({ entry, id }) => {
  const [popupContent, setPopupContent] = useState(
    "This is the anonymised response"
  );
  const [isCopied, setIsCopied] = useState(false);
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [entry]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(entry.chatResponse || entry.imageResponse)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      });
  };

  const isThread = id?.startsWith("thread_");

  return (
    <div className="head_conversation_ai_safe">
      <div className="conversation_ai_safe">
        <div className="original_prompt">
          <div>{entry.originalPrompt}</div>
        </div>
        {!isThread && (
          <div className="anony_ai_safe">
            <div className="img_head_ai_safe">
              <img
                src={anonymous}
                alt="Anonymous"
                className="img_conversation"
                onMouseEnter={() =>
                  setPopupContent("This is the anonymised response")
                }
              />
              <div className="popup">{popupContent}</div>
            </div>
            <div className="anonymised_prompt">
              {entry.anonymizedPrompt || entry.originalPrompt}
            </div>
          </div>
        )}
        <div className="mt-2">
          <strong>Chat Response:</strong>
          <ReactMarkdown
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (
                  <CodeBlock
                    language={match[1]}
                    value={String(children).replace(/\n$/, "")}
                  />
                ) : (
                  <code {...props} className={className}>
                    {children}
                  </code>
                );
              },
            }}
          >
            {isThread ? entry.anonymizedPrompt : entry.chatResponse || ""}
          </ReactMarkdown>
          {entry.imageResponse === "Generating image..." && (
            <div className="loading">Generating image ...</div>
          )}
          {entry?.imageResponse&&entry?.imageResponse?.startsWith("http") && (
            // <img
            //   alt="img"
            //   src={entry.imageResponse}
            //   style={{ maxWidth: "100%" }}
            // />
            <a
              href={entry.imageResponse}
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              Click here to view the image
            </a>
          )}
          <div
            className={`copy-button_ai mt-1 ${isCopied ? "copied" : ""}`}
            onClick={copyToClipboard}
          >
            {isCopied ? <FaCheck /> : <MdContentCopy />}
          </div>
        </div>
        <div ref={chatEndRef} />
      </div>
    </div>
  );
};

export default ConversationEntry;
